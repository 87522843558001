<template src="./processeft.html" ></template>
<style scoped src="./processeft.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import utils from '@/utils/utils.vue'
import modal from '@/components/modal/notification/modal'

import moment from 'moment'
export default {
    data () {
        return {
            customerEmail : '',
            transactionId : '',
            resdata :{
                requestid:'',
                notes: [],
            },
            currentPage: 1,
            perPage : 8,
            xmlData: null,
            notes: '',
            loaderVisible :false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Note', label: 'Note' }
            ]
        }
    },
    components : {
        Loading, modal
    },
    methods : {
        async handleAddNotesEvent(){
            try {
                this.loaderVisible = true
                await TransactionService.methods.AddTransactionNotes(this.notes,this.resdata.referneceNumber)
                .then(async() => {
                    await this.updateNotesTable()
                    this.loaderVisible = false
                    this.notes = ''  
                    this.showAlert(1, 'Notes Added Successfully')
                })
                .catch( err => {
                    this.loaderVisible = false
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)                               
                })
            } catch (error) {
                //
            }
        },
        goBack(){
            this.$router.push({
                name: 'ProcessEft',
                params: {
                    filters : this.$route.params.filters
                }
            })
        },
         delay(n) {
            return new Promise(function(resolve){
                setTimeout(resolve,n*1000);
            });
        },
        async processEFT(){
            try {
                this.loaderVisible= true
                await TransactionService.methods.ProcessEFT({
                    CustomerEmail : this.customerEmail,
                    ReferenceNumber : this.resdata.referneceNumber,
                    CustomerIsReliable : this.resdata.isCustomerReliable
                })
                .then(() =>{
                    this.loaderVisible = false
                    this.showAlert(1, "EFT Transaction Processed Successfully")
                })
                .catch(err => {
                    this.loaderVisible = false
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                await this.delay(1)
                this.goBack()
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async updateNotesTable() {
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            }).then(async res => {
                this.resdata.notes = await this.mapNotes(res.csTransactionDetailsResponse.TransactionNotes?.csTransactionNote)
            })
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        mapNotes(transactionNotes){
            if(transactionNotes) {
                transactionNotes = Array.isArray(transactionNotes) ? transactionNotes : [transactionNotes]
                transactionNotes.map(notesData => {
                    Object.keys(notesData).map(key=>{
                        notesData[key] = notesData[key]?._text
                        if(key === 'CreateDateUtc') {
                            notesData[key] = utils.methods.UTCtoLocal(moment(notesData[key]).format('MM/DD/YYYY HH:mm:ss'))
                        }
                    })
                })
                return transactionNotes
            } else {
                return []
            }
        }
    },
    async mounted () {
        try {
            this.loaderVisible = true
            this.customerEmail = this.$route.params.email
            this.transactionId = this.$route.params.Transactionid
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            })
            .then(async res => {
                // this.resdata.parentReferenceNumber = res.csTransactionDetailsResponse.TransactionData.ParentReferenceNumber._text
                this.resdata.requestId=res.csTransactionDetailsResponse.RequestId?._text
                this.resdata.customerEmail=res.csTransactionDetailsResponse.TransactionData.SenderEmail._text
                this.resdata.transactionNumber=res.csTransactionDetailsResponse.TransactionData.TransactionNumber._text
                this.resdata.referneceNumber=res.csTransactionDetailsResponse.TransactionData.ReferenceNumber._text
                this.resdata.dateAndTime = utils.methods.UTCtoLocal(moment(moment(res.csTransactionDetailsResponse.TransactionData.DateTime._text, 'MM/DD/YYYY HH:mm:ss')._d).format( 'MM-DD-YYYY HH:mm:ss'))
                this.resdata.transactionType=res.csTransactionDetailsResponse.TransactionData.TransactionType._text
                this.resdata.destinationAmount=res.csTransactionDetailsResponse.TransactionData.DestAmount._text
                this.resdata.requestedAmount=res.csTransactionDetailsResponse.TransactionData.RequestedAmount._text
                this.resdata.serviceCharge=res.csTransactionDetailsResponse.TransactionData.ServiceCharge._text
                this.resdata.sourceAmount=res.csTransactionDetailsResponse.TransactionData.SourceAmount._text
                this.resdata.phoneNumber=res.csTransactionDetailsResponse.TransactionData.PhoneNumber._text
                this.resdata.notes = await this.mapNotes(res.csTransactionDetailsResponse.TransactionNotes?.csTransactionNote)
                this.xmlData=res.csTransactionDetailsResponse.TransactionData.RemittanceData._text
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
                this.loaderVisible=false
            })
            let remittanceData = utils.methods.getParseObjectFromXml(this.xmlData)
            this.resdata.isCustomerReliable = remittanceData.MoneyTransferRequestSavedData.MrmsCallData.CustomerIsReliable._text.toLowerCase()
            this.loaderVisible = false   
        } catch (error) {
            this.loaderVisible = false
        }
    },
    computed : {
      rows () {
            return this.resdata.notes?.length
       }
    }
}
</script>
